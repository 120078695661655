import { observer } from 'mobx-react';
import * as React from 'react';
import SegmentStore from '../../../../../../SegmentStore';
import AutoSaveReactions from '../../../../Reactions/AutoSaveReactions';
import { FieldValue, SelectFormField, SelectFormFieldOption } from '../../../../Types';
import Label from '../../../Label';
import * as fieldStyle from '../../../style.module.css';
import DropdownSelectReactions from './Reactions';
import DropdownSelectFieldStore from './Store';
import * as style from './style.module.css';
import DropdownStringInput from './DropdownStringInput';
import { find, first } from '@execonline-inc/collections';
import { convertToFieldValue, stringInputOptionLabel } from '../../../Helpers';
import { Maybe, just } from 'maybeasy';
import { assertNever } from '@kofno/piper';

interface Props {
  segmentStore: SegmentStore;
  fieldDescription: SelectFormField;
  uuid: string;
}

class DropdownSelect extends React.Component<Props> {
  selectFieldStore: DropdownSelectFieldStore = new DropdownSelectFieldStore(this.props.uuid);

  componentDidMount() {
    this.selectFieldStore.initialize(this.props.segmentStore, this.props.fieldDescription);
  }

  findOptionFromTargetValue = (value: string) =>
    find((option) => {
      switch (option.kind) {
        case 'string':
          return option.name === value;
        case 'base-option':
        case 'coach-option':
        case 'leadership-persona-option':
          return option.value === value;
        default:
          assertNever(option);
      }
    }, this.props.fieldDescription.options);

  formatOptionToFieldValue = (option: SelectFormFieldOption): Maybe<FieldValue> => {
    switch (option.kind) {
      case 'string':
        return just(convertToFieldValue(stringInputOptionLabel(option), option.value));
      case 'base-option':
      case 'coach-option':
      case 'leadership-persona-option':
        return just(convertToFieldValue(option.label, option.value));
      default:
        assertNever(option);
    }
  };

  handleUpdate = (value: string) => {
    this.props.segmentStore.processRequest();

    this.findOptionFromTargetValue(value)
      .andThen(this.formatOptionToFieldValue)
      .do((fieldValue) => this.selectFieldStore.setValue([fieldValue]))
      .elseDo(() => this.selectFieldStore.clearValue());

    this.selectFieldStore.autoSave();
  };

  getDisplayValueFromOption = (option: SelectFormFieldOption) => {
    switch (option.kind) {
      case 'string':
        return option.name;
      case 'base-option':
      case 'coach-option':
      case 'leadership-persona-option':
        return option.value;
      default:
        assertNever(option);
    }
  };

  findOptionFromFieldValue = (fieldValue: FieldValue) =>
    find((option) => {
      switch (option.kind) {
        case 'string':
          return stringInputOptionLabel(option) === fieldValue.label;
        case 'base-option':
        case 'coach-option':
        case 'leadership-persona-option':
          return option.label === fieldValue.label;
        default:
          assertNever(option);
      }
    }, this.props.fieldDescription.options);

  dropdownDisplayValue = () =>
    first(this.selectFieldStore.value)
      .andThen(this.findOptionFromFieldValue)
      .map(this.getDisplayValueFromOption)
      .getOrElseValue('');

  render() {
    switch (this.selectFieldStore.state.kind) {
      case 'waiting':
      case 'initializing':
        return <DropdownSelectReactions store={this.selectFieldStore} fireImmediately={true} />;
      case 'ready':
      case 'auto-saving':
        const { fieldDescription } = this.props;
        return (
          <div className={fieldStyle.field}>
            <Label label={fieldDescription.label} />
            <select
              id={this.props.uuid}
              name={fieldDescription.name}
              data-test-select={fieldDescription.name}
              onChange={(e) => this.handleUpdate(e.target.value)}
              value={this.dropdownDisplayValue()}
              className={style.dropdownField}
            >
              <option value=""></option>
              {fieldDescription.options.map((option) => {
                switch (option.kind) {
                  case 'string':
                    return (
                      <option key={option.name} value={option.name}>
                        {stringInputOptionLabel(option)}
                      </option>
                    );
                  case 'base-option':
                  case 'coach-option':
                  case 'leadership-persona-option':
                    return (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    );
                  default:
                    assertNever(option);
                }
              })}
            </select>
            {fieldDescription.options.map((option) => {
              switch (option.kind) {
                case 'string':
                  return (
                    <DropdownStringInput
                      key={option.name}
                      selectFieldStore={this.selectFieldStore}
                      stringFormField={option}
                      dropdownValue={this.dropdownDisplayValue()}
                    />
                  );
                case 'base-option':
                case 'coach-option':
                case 'leadership-persona-option':
                  return <></>;
                default:
                  assertNever(option);
              }
            })}
            <AutoSaveReactions
              store={this.selectFieldStore}
              segmentStore={this.props.segmentStore}
              debounceDelay={1000}
            />
            <DropdownSelectReactions store={this.selectFieldStore} />
          </div>
        );
    }
  }
}

export default observer(DropdownSelect);
