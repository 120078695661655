import {
  explicitMaybe,
  mergeObjectDecoders,
  numberToStringDecoder,
  stringLiteral,
} from '@execonline-inc/decoders';
import { alreadyTranslatedText } from '@execonline-inc/translations';
import { identity } from '@kofno/piper';
import Decoder, { array, boolean, field, number, oneOf, string, succeed } from 'jsonous';
import { resourceDecoder } from '../../Resource/Decoders';
import { reqHlsVideoAssetResourceDecoder } from '../../VideoStore/ReqHls/Decoders';
import {
  CoachProfile,
  CoachProfileResource,
  CoachSelectFormField,
  CoachSelectFormFieldObject,
  CoachSelectFormFieldOption,
  CoachSelectFormFieldParts,
  FieldDescription,
  FieldValue,
  FormFieldAsset,
  FormFieldAssetResource,
  FormFieldError,
  FormFieldMode,
  FormFieldObject,
  FormFieldOutput,
  ImageType,
  LeadershipPersona,
  LeadershipPersonaKind,
  LeadershipPersonaResource,
  LeadershipPersonaSelectFormField,
  LeadershipPersonaSelectFormFieldObject,
  LeadershipPersonaSelectFormFieldOption,
  LeadershipPersonaSelectFormFieldParts,
  SelectFormField,
  SelectFormFieldBaseOption,
  SelectFormFieldOption,
  SelectFormFieldOptionImage,
  SelectFormFieldParts,
  StringFormField,
  StringFormFieldContent,
  StringFormFieldParts,
} from './Types';

export const formFieldModeDecoder: Decoder<FormFieldMode> = oneOf([
  stringLiteral<FormFieldMode>('readonly'),
  stringLiteral<FormFieldMode>('required'),
  stringLiteral<FormFieldMode>('writable'),
]);

export const formFieldErrorDecoder: Decoder<FormFieldError> = succeed({})
  .assign('kind', field('kind', string))
  .assign('message', field('message', string));

export const baseFormFieldObjectDecoder: Decoder<FormFieldObject> = succeed({})
  .assign('name', field('name', string))
  .assign('label', field('label', explicitMaybe(alreadyTranslatedText)))
  .assign('errors', field('errors', array(formFieldErrorDecoder)))
  .assign('mode', field('mode', formFieldModeDecoder));

export const coachSelectFormFieldObjectDecoder: Decoder<CoachSelectFormFieldObject> = succeed({})
  .assign('name', field('name', stringLiteral('coach-select')))
  .assign('label', field('label', explicitMaybe(alreadyTranslatedText)))
  .assign('errors', field('errors', array(formFieldErrorDecoder)))
  .assign('mode', field('mode', formFieldModeDecoder));

export const leadershipPersonaSelectFormFieldObjectDecoder: Decoder<LeadershipPersonaSelectFormFieldObject> =
  succeed({})
    .assign('name', field('name', stringLiteral('leadership-persona-selection')))
    .assign('label', field('label', explicitMaybe(alreadyTranslatedText)))
    .assign('errors', field('errors', array(formFieldErrorDecoder)))
    .assign('mode', field('mode', formFieldModeDecoder));

export const formFieldObjectDecoder: Decoder<FormFieldObject> = oneOf<FormFieldObject>([
  coachSelectFormFieldObjectDecoder.map<FormFieldObject>(identity),
  leadershipPersonaSelectFormFieldObjectDecoder.map<FormFieldObject>(identity),
  baseFormFieldObjectDecoder.map<FormFieldObject>(identity),
]);

export const stringFormFieldContentDecoder: Decoder<StringFormFieldContent> = succeed({})
  .assign('type', field('type', stringLiteral('text/plain')))
  .assign('multiline', field('multiline', boolean));

export const stringFormFieldPartsDecoder: Decoder<StringFormFieldParts> = succeed({})
  .assign('kind', field('kind', stringLiteral('string')))
  .assign('value', field('value', string))
  .assign('content', field('content', stringFormFieldContentDecoder))
  .assign('maxlength', field('maxlength', number))
  .assign('minlength', field('minlength', explicitMaybe(number)))
  .assign('placeholder', field('placeholder', explicitMaybe(string)));

export const imageTypeDecoder: Decoder<ImageType> = oneOf([
  stringLiteral<ImageType>('image/jpeg'),
  stringLiteral<ImageType>('image/png'),
]);

export const selectFormFieldOptionImageDecoder: Decoder<SelectFormFieldOptionImage> = succeed({})
  .assign('type', field('type', imageTypeDecoder))
  .assign('data', field('data', string))
  .assign('src', field('src', string))
  .assign('alt', field('alt', string));

export const selectBaseOptionDecoder: Decoder<SelectFormFieldBaseOption> = succeed({})
  .assign('kind', field('kind', stringLiteral('base-option')))
  .assign('label', field('label', string))
  .assign('value', field('value', string))
  .assign('image', field('image', explicitMaybe(selectFormFieldOptionImageDecoder)));

export const formFieldValueDecoder: Decoder<FieldValue> = succeed({})
  .assign('label', field('label', string))
  .assign('value', field('value', string));

export const coachProfileDecoder: Decoder<CoachProfile> = succeed({})
  .assign('id', field('id', number))
  .assign('user_id', field('user_id', number))
  .assign('name', field('name', string))
  .assign('coachingStyle', field('coaching_style', alreadyTranslatedText))
  .assign('coachLeadershipExperience', field('coach_leadership_experience', alreadyTranslatedText))
  .assign(
    'introductoryVideo',
    field('introductory_video', explicitMaybe(reqHlsVideoAssetResourceDecoder)),
  );

export const coachProfileResourceDecoder: Decoder<CoachProfileResource> =
  resourceDecoder(coachProfileDecoder);

export const coachSelectOptionDecoder: Decoder<CoachSelectFormFieldOption> = succeed({})
  .assign('kind', field('kind', stringLiteral('coach-option')))
  .assign('label', field('label', string))
  .assign('value', field('value', numberToStringDecoder))
  .assign('image', field('image', explicitMaybe(selectFormFieldOptionImageDecoder)))
  .assign('resource', field('resource', explicitMaybe(coachProfileResourceDecoder)));

export const coachSelectFormFieldPartsDecoder: Decoder<CoachSelectFormFieldParts> = succeed({})
  .assign('kind', field('kind', stringLiteral('multiple-selection')))
  .assign('minSelections', field('minSelections', number))
  .assign('maxSelections', field('maxSelections', number))
  .assign('value', field('value', array(string)))
  .assign('options', field('options', array(coachSelectOptionDecoder)));

export const leadershipPersonaKindDecoder: Decoder<LeadershipPersonaKind> = oneOf([
  stringLiteral<LeadershipPersonaKind>('visionary-vinny'),
  stringLiteral<LeadershipPersonaKind>('decisive-darcy'),
  stringLiteral<LeadershipPersonaKind>('collaborative-charlie'),
  stringLiteral<LeadershipPersonaKind>('adaptive-alex'),
  stringLiteral<LeadershipPersonaKind>('empathetic-elliot'),
]);

export const leadershipPersonaDecoder: Decoder<LeadershipPersona> = succeed({})
  .assign('kind', field('kind', leadershipPersonaKindDecoder))
  .assign('traits', field('traits', array(alreadyTranslatedText)));

export const leadershipPersonaResourceDecoder: Decoder<LeadershipPersonaResource> =
  resourceDecoder(leadershipPersonaDecoder);

export const leadershipPersonaSelectFormFieldOptionDecoder: Decoder<LeadershipPersonaSelectFormFieldOption> =
  succeed({})
    .assign('kind', field('kind', stringLiteral('leadership-persona-option')))
    .assign('label', field('label', string))
    .assign('value', field('value', string))
    .assign('image', field('image', explicitMaybe(selectFormFieldOptionImageDecoder)))
    .assign('resource', field('resource', explicitMaybe(leadershipPersonaResourceDecoder)));

export const leadershipPersonaSelectFormFieldPartsDecoder: Decoder<LeadershipPersonaSelectFormFieldParts> =
  succeed({})
    .assign('kind', field('kind', stringLiteral('multiple-selection')))
    .assign('minSelections', field('minSelections', number))
    .assign('maxSelections', field('maxSelections', number))
    .assign('value', field('value', array(string)))
    .assign('options', field('options', array(leadershipPersonaSelectFormFieldOptionDecoder)));

const leadershipPersonaSelectFormFieldDecoder: Decoder<LeadershipPersonaSelectFormField> =
  mergeObjectDecoders(
    leadershipPersonaSelectFormFieldObjectDecoder,
    leadershipPersonaSelectFormFieldPartsDecoder,
  );

const stringFormFieldDecoder: Decoder<StringFormField> = mergeObjectDecoders(
  formFieldObjectDecoder,
  stringFormFieldPartsDecoder,
);

export const selectOptionDecoder: Decoder<SelectFormFieldOption> = oneOf<SelectFormFieldOption>([
  selectBaseOptionDecoder.map<SelectFormFieldOption>(identity),
  stringFormFieldDecoder.map<SelectFormFieldOption>(identity),
]);

export const selectFormFieldPartsDecoder: Decoder<SelectFormFieldParts> = succeed({})
  .assign('kind', field('kind', stringLiteral('multiple-selection')))
  .assign('minSelections', field('minSelections', number))
  .assign('maxSelections', field('maxSelections', number))
  .assign('value', field('value', array(string)))
  .assign('options', field('options', array(selectOptionDecoder)));

const coachSelectFormFieldDecoder: Decoder<CoachSelectFormField> = mergeObjectDecoders(
  coachSelectFormFieldObjectDecoder,
  coachSelectFormFieldPartsDecoder,
);

const selectFormFieldDecoder: Decoder<SelectFormField> = mergeObjectDecoders(
  formFieldObjectDecoder,
  selectFormFieldPartsDecoder,
);

const fieldDescriptionDecoder: Decoder<FieldDescription> = oneOf<FieldDescription>([
  stringFormFieldDecoder.map<FieldDescription>(identity),
  coachSelectFormFieldDecoder.map<FieldDescription>(identity),
  leadershipPersonaSelectFormFieldDecoder.map<FieldDescription>(identity),
  selectFormFieldDecoder.map<FieldDescription>(identity),
]);

export const formFieldAssetDecoder: Decoder<FormFieldAsset> = succeed({})
  .assign('id', field('id', number))
  .assign('uuid', field('uuid', string))
  .assign('fieldDescription', field('field_description', fieldDescriptionDecoder));

export const formFieldAssetResourceDecoder: Decoder<FormFieldAssetResource> =
  resourceDecoder(formFieldAssetDecoder);

export const formFieldOutputDecoder: Decoder<FormFieldOutput> = succeed({})
  .assign('id', field('id', string))
  .assign('value', field('value', array(formFieldValueDecoder)));
