import { observer } from 'mobx-react';
import { LeadershipPersonaSelectFormFieldOption, FieldValue } from '../../../Types';
import { emptyFragment, Typography } from '@execonline-inc/execonline-ui';
import { useEffect, useState } from 'react';
import { equals, first } from '@execonline-inc/collections';
import { when } from '@execonline-inc/maybe-adapter';
import PersonaIcon from './PersonaIcon';
import PersonaTraits from './PersonaTraits';
import { convertToFieldValue } from '../../Helpers';

interface Props {
  option: LeadershipPersonaSelectFormFieldOption;
  setFieldValue: React.Dispatch<React.SetStateAction<FieldValue[]>>;
  fieldValue: FieldValue[];
}

type SelectedBackgroundColor = 'bg-gray-100';
type UnselectedBackgroundColor = 'bg-[#FDFDFD]';
type BackgroundColor = SelectedBackgroundColor | UnselectedBackgroundColor;

function LeadershipPersonaOption({ option, setFieldValue, fieldValue }: Props) {
  const selectedBackgroundColor: SelectedBackgroundColor = 'bg-gray-100';
  const unselectedBackgroundColor: UnselectedBackgroundColor = 'bg-[#FDFDFD]';
  const [backgroundColor, setBackgroundColor] =
    useState<BackgroundColor>(unselectedBackgroundColor);

  useEffect(() => {
    first(fieldValue)
      .andThen((selectedOption) => when(equals(selectedOption.value, option.value), {}))
      .map(() => setBackgroundColor(selectedBackgroundColor))
      .getOrElse(() => setBackgroundColor(unselectedBackgroundColor));
  });

  return (
    <div
      className={`${backgroundColor} flex cursor-pointer flex-col items-start gap-3 rounded-md border border-[#E7E7E7] p-4`}
      onClick={() => setFieldValue([convertToFieldValue(option.label, option.value)])}
    >
      <div className="contents text-4xl">
        <PersonaIcon option={option} />
      </div>
      <Typography className="text-lg font-semibold" key={option.value}>
        {option.label}
      </Typography>
      {option.resource
        .map((resource) => <PersonaTraits traits={resource.payload.traits} option={option} />)
        .getOrElse(emptyFragment)}
    </div>
  );
}

export default observer(LeadershipPersonaOption);
