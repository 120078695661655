import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { FieldValue, StringFormField } from '../../../../../Types';
import * as style from './../style.module.css';
import { find } from '@execonline-inc/collections';
import { formatInputValue, inputValue, stringInputOptionLabel } from '../../../../Helpers';
import { Maybe } from 'maybeasy';

interface Props {
  fieldValue: FieldValue[];
  stringFormField: StringFormField;
  removeValue: (value: FieldValue) => void;
  addValue: (value: FieldValue) => void;
  whenMaxSelectionValid: () => Maybe<{}>;
}

function OptionWithStringInput({
  fieldValue,
  stringFormField,
  removeValue,
  addValue,
  whenMaxSelectionValid,
}: Props) {
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  useEffect(() => {
    find((v) => v.label === stringInputOptionLabel(stringFormField), fieldValue).do(() =>
      setCheckboxChecked(true),
    );
  });

  const onCheckboxToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      removeValue(formatInputValue(stringFormField, e.currentTarget.value));
      setCheckboxChecked(false);
    } else {
      whenMaxSelectionValid().do(() => {
        addValue(formatInputValue(stringFormField, ''));
        setCheckboxChecked(true);
      });
    }
  };

  return (
    <>
      <label key={stringFormField.name} className={style.checkboxLabel}>
        <input
          name={stringFormField.name}
          type="checkbox"
          data-test-checkbox={stringFormField.name}
          onChange={onCheckboxToggle}
          checked={checkboxChecked}
          className={style.checkboxField}
        />
        {stringInputOptionLabel(stringFormField)}
      </label>
      {checkboxChecked && (
        <textarea
          name={stringFormField.name}
          value={inputValue(stringFormField, fieldValue)}
          required={true}
          minLength={stringFormField.minlength.getOrElseValue(0)}
          maxLength={stringFormField.maxlength}
          onChange={(e) => addValue(formatInputValue(stringFormField, e.currentTarget.value))}
          className={style.textArea}
        />
      )}
    </>
  );
}

export default observer(OptionWithStringInput);
