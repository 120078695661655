import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../../../../Translations';
import ModalStore from '../../../../../Modal/Store';
import { CoachProfileResource, SelectFormFieldOptionImage } from '../../../../Types';
import CoachSelectStore from '../Store';
import CoachModalStates from './CoachModalStates';
import CoachTile from './CoachTile';
import CoachSelectOptionStore from './Store';
import * as style from './style.module.css';
import { convertToFieldValue } from '../../../Helpers';

interface Props {
  coachSelectStore: CoachSelectStore;
  coachProfileResource: CoachProfileResource;
  image: Maybe<SelectFormFieldOptionImage>;
}

class CoachSelectOption extends React.Component<Props> {
  store = new CoachSelectOptionStore(String(this.props.coachProfileResource.payload.user_id));
  modalStore = new ModalStore();

  handleClick = () => {
    this.props.coachSelectStore.setValue(
      convertToFieldValue(this.props.coachProfileResource.payload.name, this.store.id),
    );
    this.props.coachSelectStore.autoSave();
  };

  render() {
    const { coachSelectStore, coachProfileResource, image } = this.props;
    switch (this.store.state.kind) {
      case 'waiting':
      case 'initializing':
      case 'ready':
        return (
          <div className={style.root}>
            <CoachTile
              coachProfileResource={coachProfileResource}
              image={image}
              coachSelectStore={coachSelectStore}
              coachSelectOptionStore={this.store}
              modalStore={this.modalStore}
            >
              {{
                selectButton: (
                  <button className={style.selectButton} onClick={this.handleClick}>
                    <T kind="Select Coach" />
                  </button>
                ),
              }}
            </CoachTile>
            <CoachModalStates
              coachProfileResource={coachProfileResource}
              image={image}
              modalStore={this.modalStore}
              onSelect={this.handleClick}
            />
          </div>
        );
      case 'selected':
        return (
          <div className={`${style.root} ${style.selected}`}>
            <CoachTile
              coachProfileResource={coachProfileResource}
              image={image}
              coachSelectStore={coachSelectStore}
              coachSelectOptionStore={this.store}
              modalStore={this.modalStore}
            >
              {{
                selectButton: (
                  <button className={style.selectButton}>
                    <T kind="Coach Selected" />
                  </button>
                ),
              }}
            </CoachTile>
            <CoachModalStates
              coachProfileResource={coachProfileResource}
              image={image}
              modalStore={this.modalStore}
              onSelect={this.handleClick}
            />
          </div>
        );
    }
  }
}

export default observer(CoachSelectOption);
