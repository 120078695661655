import { observer } from 'mobx-react';
import { StringFormField } from '../../../../../Types';
import * as style from '../style.module.css';
import DropdownSelectFieldStore from '../Store';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { convertToFieldValue, inputValue, stringInputOptionLabel } from '../../../../Helpers';
import { fromEmpty } from 'maybeasy';
import { always } from '@kofno/piper';
import { when } from '@execonline-inc/maybe-adapter';

interface Props {
  selectFieldStore: DropdownSelectFieldStore;
  stringFormField: StringFormField;
  dropdownValue: string;
}

function DropdownStringInput({ selectFieldStore, stringFormField, dropdownValue }: Props) {
  return fromEmpty(selectFieldStore.value)
    .andThen(always(when(dropdownValue === stringFormField.name, {})))
    .map(() => (
      <textarea
        className={style.textArea}
        name={stringFormField.name}
        value={inputValue(stringFormField, selectFieldStore.value)}
        required={true}
        minLength={stringFormField.minlength.getOrElseValue(0)}
        maxLength={stringFormField.maxlength}
        onChange={(e) =>
          selectFieldStore.setValue([
            convertToFieldValue(stringInputOptionLabel(stringFormField), e.currentTarget.value),
          ])
        }
        onKeyUp={selectFieldStore.autoSave}
      ></textarea>
    ))
    .getOrElse(emptyFragment);
}
export default observer(DropdownStringInput);
