import { first } from '@execonline-inc/collections';
import { JsonValue } from '@execonline-inc/decoders';
import { asMaybe } from '@execonline-inc/error-handling';
import { when } from '@execonline-inc/maybe-adapter';
import { whenLt } from '@execonline-inc/numbers';
import { array } from 'jsonous';
import { just, Maybe } from 'maybeasy';
import { useEffect, useState } from 'react';
import { getFieldValue } from '../../../FormFields';
import SegmentStore from '../../../SegmentStore';
import { formFieldValueDecoder } from '../Decoders';
import {
  FieldDescription,
  FieldValue,
  FormFieldOutputValue,
  SelectFormField,
  StringFormField,
} from '../Types';
import { convertToFieldValue } from './Helpers';

export const whenMaxLengthValid = (
  fieldValue: FieldValue[],
  fieldDescription: SelectFormField | StringFormField,
): Maybe<number> => {
  switch (fieldDescription.kind) {
    case 'multiple-selection':
      return just(fieldValue.length).andThen(whenLt(fieldDescription.maxSelections));
    case 'string':
      return first(fieldValue)
        .map((fieldValue) => fieldValue.value.length)
        .andThen(whenLt(fieldDescription.maxlength));
  }
};

const validateValue = (value: FormFieldOutputValue): Maybe<FieldValue[]> =>
  asMaybe(array(formFieldValueDecoder).decodeAny(value));

const fieldValueM = (results: Maybe<JsonValue>, uuid: string) =>
  getFieldValue(results, uuid).andThen(validateValue).getOrElseValue([]);

export const fieldHasValue = (value: FieldValue, fieldValue: FieldValue[]): boolean =>
  fieldValue.some((v) => v.label === value.label);

export function useFormField(
  segmentStore: SegmentStore,
  fieldDescription: FieldDescription,
  uuid: string,
) {
  const [fieldValue, setFieldValue] = useState<FieldValue[]>([]);
  const [isRequired, setIsRequired] = useState(false);

  useEffect(() => {
    setFieldValue(fieldValueM(segmentStore.results, uuid));
    setIsRequired(fieldDescription.mode === 'required');
  }, []);

  useEffect(() => {
    segmentStore.addEmbeddedFormFieldOutput({
      id: uuid,
      value: fieldValue,
    });

    const handler = setTimeout(() => {
      segmentStore.autoSaveFormFields();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [fieldValue]);

  const addValue = (value: FieldValue) => {
    const newFieldValue = fieldValue
      .filter((v) => v.label !== value.label)
      .concat(convertToFieldValue(value.label, value.value));
    setFieldValue(newFieldValue);
  };

  const removeValue = (value: FieldValue) => {
    const newFieldValue = fieldValue.filter((v) => v.label !== value.label);
    setFieldValue(newFieldValue);
  };

  const validateAndAddValue = (option: FieldValue) =>
    whenMaxLengthValid(fieldValue, fieldDescription).do(() => addValue(option));

  const onValueChange = (option: FieldValue) =>
    when(fieldHasValue(option, fieldValue), true)
      .do(() => removeValue(option))
      .elseDo(() => validateAndAddValue(option));

  return {
    fieldValue,
    isRequired,
    onValueChange,
    addValue,
    removeValue,
    setFieldValue,
  };
}
