import { Maybe } from 'maybeasy';
import SegmentStore from '../../SegmentStore';
import { observer } from 'mobx-react';
import { EmbeddedTabularFormFieldGroupEmbeddable } from '../ContentEmbed/Types';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import EmbeddedTabularFormFieldAsset from '../EmbeddedFormFieldAsset/EmbeddedTabularFormFieldAsset';
import { AlreadyTranslated } from '@execonline-inc/translations';

interface Props {
  segmentStore: Maybe<SegmentStore>;
  embeddable: EmbeddedTabularFormFieldGroupEmbeddable;
}

function EmbeddedTabularFormFieldGroup({ embeddable, segmentStore }: Props) {
  return (
    <>
      {embeddable.title
        .map((title) => (
          <span className="p-y-5 font-medium">
            <AlreadyTranslated content={title.content} />
          </span>
        ))
        .getOrElse(emptyFragment)}
      <table className="min-w-full border-collapse">
        <thead>
          <tr className="border-b border-slate-200">
            <th className="w-70" />
            {embeddable.options.map((option) =>
              option.content
                .map((content) => (
                  <th key={content.text} className="px-2 py-2 text-center font-medium">
                    <AlreadyTranslated content={content} />
                  </th>
                ))
                .getOrElse(emptyFragment),
            )}
          </tr>
        </thead>
        <tbody>
          {console.log('FIELDS: ', embeddable.fields)}
          {embeddable.fields.map((field) => (
            <EmbeddedTabularFormFieldAsset
              key={field.uuid}
              uuid={field.uuid}
              embeddedFormFieldAssets={embeddable.assets}
              segmentStore={segmentStore}
              options={embeddable.options}
            />
          ))}
        </tbody>
      </table>
    </>
  );
}
export default observer(EmbeddedTabularFormFieldGroup);
