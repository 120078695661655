import { equals, find } from '@execonline-inc/collections';
import { FieldValue, StringFormField } from '../Types';

export const stringInputOptionLabel = (stringFormField: StringFormField) =>
  stringFormField.label.map((label) => label.text).getOrElseValue(stringFormField.name);

export const convertToFieldValue = (label: string, value: string): FieldValue => ({
  label,
  value,
});

export const inputValue = (stringFormField: StringFormField, fieldValue: FieldValue[]) =>
  find((v) => equals(v.label, stringInputOptionLabel(stringFormField)), fieldValue)
    .map((v) => v.value)
    .getOrElseValue('');

export const formatInputValue = (
  stringFormField: StringFormField,
  stringFieldValue: string,
): FieldValue => ({
  label: stringInputOptionLabel(stringFormField),
  value: stringFieldValue,
});
