import { observer } from 'mobx-react';
import SegmentStore from '../../../../../SegmentStore';
import { LeadershipPersonaSelectFormField } from '../../../Types';
import LeadershipPersonaOption from './LeadershipPersonaOption';
import { AlreadyTranslated } from '@execonline-inc/translations';
import { emptyFragment, Typography } from '@execonline-inc/execonline-ui';
import { useFormField } from '../../useFormField';

interface Props {
  segmentStore: SegmentStore;
  fieldDescription: LeadershipPersonaSelectFormField;
  uuid: string;
}

function LeadershipPersonaSelect({ segmentStore, fieldDescription, uuid }: Props) {
  const { fieldValue, setFieldValue } = useFormField(segmentStore, fieldDescription, uuid);

  return (
    <fieldset>
      <Typography className="text-lg">
        {fieldDescription.label
          .map((l) => <AlreadyTranslated content={l} />)
          .getOrElse(emptyFragment)}
      </Typography>
      <div className="mt-5 flex flex-wrap content-start items-start gap-2 self-stretch">
        {fieldDescription.options.map((option) => (
          <LeadershipPersonaOption
            key={option.value}
            option={option}
            setFieldValue={setFieldValue}
            fieldValue={fieldValue}
          />
        ))}
      </div>
    </fieldset>
  );
}

export default observer(LeadershipPersonaSelect);
